var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app"
  }, [_c('div', {
    staticClass: "app__inner"
  }, [_c('the-auth-content')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }