








import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    name: 'Auth',
    head: {
        titleTemplate: 'Workle - %s',
        __dangerouslyDisableSanitizers: [ 'script', 'noscript' ],
        script: [
            {
                hid: 'myTracker-customer',
                innerHTML: `
                    var _tmr = window._tmr || (window._tmr = []);
                    _tmr.push({id: "3504723", type: "pageView", start: (new Date()).getTime()});
                    (function (d, w, id) {
                      if (d.getElementById(id)) return;
                      var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                      ts.src = "https://top-fwz1.mail.ru/js/code.js";
                      var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                      if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
                    })(document, window, "tmr-code");
                `,
                type: 'text/javascript',
                charset: 'utf-8'
            },
            {
                src: '//code.jivo.ru/widget/UD2Uzfk632',
                async: true
            },
            {
                hid: 'gtm',
                innerHTML: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-KDXVNMW4');
                `
            }
        ],
        noscript: [
            {
                hid: 'meTracker',
                innerHTML: `
                    <div>
                        <img
                            src="https://top-fwz1.mail.ru/counter?id=3504723;js=na"
                            style="position:absolute;left:-9999px;"
                            alt="Top.Mail.Ru"
                        />
                    </div>
                `
            },
            {
                hid: 'gtm',
                innerHTML: `
                    <iframe
                        src="https://www.googletagmanager.com/ns.html?id=GTM-KDXVNMW4"
                        height="0"
                        width="0"
                        style="display:none; visibility:hidden"
                    ></iframe>
                `
            }
        ]
    }
})
