import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f6635216 = () => interopDefault(import('../src/pages/auth/activate.vue' /* webpackChunkName: "pages/auth/activate" */))
const _7d46fcd7 = () => interopDefault(import('../src/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _4a88a647 = () => interopDefault(import('../src/pages/auth/login-as-claim.vue' /* webpackChunkName: "pages/auth/login-as-claim" */))
const _31011832 = () => interopDefault(import('../src/pages/auth/logout-as-claim.vue' /* webpackChunkName: "pages/auth/logout-as-claim" */))
const _4bbb47fc = () => interopDefault(import('../src/pages/auth/restore.vue' /* webpackChunkName: "pages/auth/restore" */))
const _77f28b1a = () => interopDefault(import('../src/pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activate",
    component: _f6635216,
    name: "activate"
  }, {
    path: "/login",
    component: _7d46fcd7,
    name: "login"
  }, {
    path: "/login-as-claim",
    component: _4a88a647,
    name: "login-as-claim"
  }, {
    path: "/logout-as-claim",
    component: _31011832,
    name: "logout-as-claim"
  }, {
    path: "/restore",
    component: _4bbb47fc,
    name: "restore"
  }, {
    path: "/signup",
    component: _77f28b1a,
    name: "signup"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
